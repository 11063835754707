.set {
	height: calc(100vh - 56px);
	width: 400px;
	box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
	display: flex;
	flex-direction: column;

	.header {
		font-size: 24px;
		line-height: 28px;
		background-color: var(--gray-background);
		border-bottom: 1px solid var(--gray-border);

		.title {
			width: 100%;
			font-weight: 400;
			display: flex;
			align-items: center;
			padding: 16px;

			.close {
				margin-left: auto;
			}
		}

		.search {
			width: 260px;
			padding: 0 6px;
		}

		.switch {
			cursor: pointer;

			svg {
				fill: #0009;
			}

			svg:hover {
				fill: var(--color-hover);
			}
		}
	}

	.table {
		width: 100%;
		color: var(--gray-text);

		th,
		td {
			padding: 8px;
			font-size: 12px;
		}

		.row {
			height: 40px;

			.radio {
				border: 0px;
				width: 100%;
				height: 20px;
				accent-color: var(--green);
				cursor: pointer;
			}

			.radio:hover {
				accent-color: var(--green);
			}

			.icon {
				height: 20px;
				width: 20px;
			}

			.delete {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				display: none;
			}
		}

		.row:hover {
			background-color: var(--gray-hover);
			position: relative;
			cursor: pointer;

			.delete {
				display: inline-block;
			}

			// background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #ecf6f0 24px);
			.icons {
				display: flex;
				justify-content: space-between;
				position: absolute;
				background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #f5f5f5 24px);
				padding: 4px 10px 3px 15px;
				border-radius: 4px;
				width: 100px;
				right: 0px;
				top: -22px;
				z-index: 50;
				color: var(--gray-text);
			}

			.icons:hover {
				color: #000;
			}
		}
	}

	.tableFilterInfo {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
		padding: 14px 10px 24px 15px;
	}

	.pagination {
		position: absolute;
		left: 0;
		right: 15px;
		bottom: 14px;
		height: 56px;
		padding-right: 16px;
		font-size: 12px;
		font-weight: 500;
		line-height: 56px;
		letter-spacing: 0.4px;
		text-align: right;
		color: #0009;
		background: linear-gradient(180deg,
				rgba(255, 255, 255, 0.01) 1.3%,
				rgba(255, 255, 255, 0.07) 5.8%,
				rgba(255, 255, 255, 0.17) 9.7%,
				rgba(255, 255, 255, 0.3) 13.5%,
				rgba(255, 255, 255, 0.45) 17.64%,
				rgba(255, 255, 255, 0.61) 22.5%,
				rgba(255, 255, 255, 0.76) 29%,
				rgba(255, 255, 255, 0.88) 37%,
				rgba(255, 255, 255, 0.96) 48%,
				#fff 61%);
	}

	.footer {
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		letter-spacing: 0.4px;
		color: var(--gray-text);
		padding: 15px;
		border-top: 1px solid var(--gray-border);
	}
}

.empty {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: var(--gray-text);

	svg {
		width: 64px;
		height: 64px;
		margin-bottom: 15px;
	}
}


.loading {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.dialogHeader {
	display: flex;
	padding: 24px 40px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	background: var(--Premium-Gradient, linear-gradient(180deg, #999 0%, #696969 100%));

	.dialogTitle {
		color: var(--Surface-Surface-White, var(--Color, #fff));
		font-feature-settings: "clig" off, "liga" off;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dialogSubtitle {
		color: var(--Surface-Surface-White, var(--Color, #fff));
		font-feature-settings: "clig" off, "liga" off;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: .033px;
	}
}

.dialogContent {
	margin: 34px 0px 0 35px;
	padding-right: 50px;
	overflow: auto;


	.dialogArticle {
		display: flex;
		margin-bottom: 16px;

		.dialogIcon {
			display: flex;
			width: 36px;
			height: 36px;
			background: rgba(139, 136, 134, .12);
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			margin-right: 19px;
		}

		h6 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: .15px;
			margin: 4px 0;
			color: #000000de;
		}

		p {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: .18px;
			text-align: left;
			margin: 4px 0;
		}

		ul {
			color: var(--On-Surface-60---Medium-Emphasis, rgba(0, 0, 0, .6));
			font-feature-settings: "clig" off, "liga" off;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: .033px;
			padding-left: 40px;
			list-style-type: disc;

			span {
				display: inline-block;
				margin-left: 2%;
			}

			a {
				color: #0009;
				cursor: pointer;
				text-decoration: underline;
			}

			div {
				width: 74%;
			}
		}

	}

	.divider {
		display: flex;
		height: 1px;
		justify-content: center;
		align-items: center;
		background-color: #dcdcdc;
		margin-bottom: 15px;
	}
}

.dialogFoot {
	padding: 16px 0;
	margin-bottom: 0;
	margin-right: 10px;
	min-height: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}