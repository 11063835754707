.key {
    flex: 0 0 130px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .4px;
    color: #0009;
    display: inline;
}

.value {
    color: #000000de;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: .4px;
}

.icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    fill: var(--gray-text);
}

.header {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 40px;
}