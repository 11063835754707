.detail {
  width: 100%;
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;

  .key {
    width: 50%;
  }

  .value {
    font-weight: 600;
  }
}
