.layers {
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 16px;
  border-bottom: 1px solid var(--gray-border);

  .header {
    display: flex;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 4px;
      fill: var(--gray-text);
    }

    .count {
      margin-left: auto;
      margin-right: 16px;
      font-size: 14px;
      color: var(--gray-border)
    }
  }

  .risk {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    background-color: rgb(233, 140, 24);

  }

  .content {
    padding: 8px 0;
  }

  .detail {
    width: 100%;
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
    margin-top: 10px;
    border-top: 1px solid #2121211f;
    padding: 10px 16px 0px;

    .key {
      flex: 0 0 150px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: .4px;
      color: #0009;
      display: inline;
    }

    .value {
      color: #000000de;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: .4px;
    }
  }

  .fathomLayer {
    padding: 0px 10px;

    .fathomHeader {
      display: flex;
      flex-direction: row;
      background-color: #f0f0f0;

      .fathomHeaderContent {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-left: 20px;
        padding: 10px 0;
        font-weight: 400;

        div {
          color: #007934;
          height: 24px;
          width: 24px;
          display: inline-block;
          fill: #007934;
        }
      }
    }

    .fathomFoot {
      background-repeat: no-repeat;
      height: 180px;
      display: flex;
      align-items: end;
      margin-left: auto;
      font-size: 14px;

      .fathomFootContent {
        margin: 6px 10px 17px 15px;
        width: 100%;
        border: 1px solid var(--Surface-Border---Outline, rgba(33, 33, 33, .12));
        background: #fff;
        box-shadow: 0 1px 6px #0000001f, 0 1px 4px #0000001f !important;
        border-radius: 4px;
        transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
        position: relative;
        padding: 16px;
        display: flex;

        span {
          text-decoration: underline;
          color: #007934;
          cursor: pointer;
        }
      }
    }
  }
}