.expander {
  display: flex;
  flex-flow: column;
}

.header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  cursor: pointer;
  padding: 16px;
}

.icon {
  height: 24px;
  width: 24px;
  margin: 1px 0 0 4px;
  flex-shrink: 0;
}

.result {
  margin-right: 16px;
  flex-shrink: 0;
  display: flex;
}

.cardColor {
  margin-left: 8px;
  margin-top: 5px;
  height: 12px;
  width: 12px;
  min-width: 12px;
  border: 1px solid rgba(0, 0, 0, .12);
  box-sizing: border-box;
  display: inline-block;
}