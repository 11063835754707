.div {
  border-radius: 4px;
  border: 1px solid var(--Surface-Border---Outline, rgba(33, 33, 33, 0.12));
  margin-left: 5px;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 6px 0;

  .section {
    display: flex;
    align-items: center;
    gap: 5px;

    .label {
      margin-left: 10px;
      overflow: hidden;
      color: var(--On-Surface-87---High-Emphasis, rgba(0, 0, 0, 0.87));
      font-feature-settings: "clig" off, "liga" off;
      text-overflow: ellipsis;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.033px;
    }

    .info {
      height: 20px !important;
      width: 20px !important;
      opacity: 0.7;
      font-size: 20px !important;
      line-height: 20px !important;
      cursor: pointer;
    }

    .icon {
      height: 24px;
      width: 24px;
    }
  }

  .score {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    margin-top: 5px;
    color: #0009;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-left: 10px;

    .key {
      width: 27%;
      font-weight: 600;
    }

    span {
      font-weight: bold;
      color: #000;
      opacity: 0.99;
    }
  }
}
