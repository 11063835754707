
.dialogHeader {
	display: flex;
	padding: 24px 40px 20px;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;
	align-self: stretch;
	background: var(--premium-gradient-2, linear-gradient(180deg, #fbbb18 0%, #b77718 100%));

	.dialogTitle {
		color: var(--Surface-Surface-White, var(--Color, #fff));
		font-feature-settings: "clig" off, "liga" off;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}

	.dialogSubtitle {
		color: var(--Surface-Surface-White, var(--Color, #fff));
		font-feature-settings: "clig" off, "liga" off;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: .033px;
	}
}

.dialogContent {
	margin: 34px 0px 0 35px;
	padding-right: 50px;
	overflow: auto;


	.dialogArticle {
		display: flex;
		margin-bottom: 16px;

		.dialogIcon {
			display: flex;
			width: 36px;
			height: 36px;
			background: rgba(139, 136, 134, .12);
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			margin-right: 19px;
		}

		h6 {
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: .15px;
			margin: 4px 0;
			color: #000000de;
		}

		p {
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 22px;
			letter-spacing: .18px;
			text-align: left;
			margin: 4px 0;
		}

		ul {
			color: var(--On-Surface-60---Medium-Emphasis, rgba(0, 0, 0, .6));
			font-feature-settings: "clig" off, "liga" off;
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px;
			letter-spacing: .033px;
			padding-left: 40px;
			list-style-type: disc;

			span {
				display: inline-block;
				margin-left: 2%;
			}

			a {
				color: #0009;
				cursor: pointer;
				text-decoration: underline;
			}

			div {
				width: 74%;
			}
		}

	}

	.divider {
		display: flex;
		height: 1px;
		justify-content: center;
		align-items: center;
		background-color: #dcdcdc;
		margin-bottom: 15px;
	}
}

.dialogFoot {
	padding: 16px 0;
	margin-bottom: 0;
	margin-right: 10px;
	min-height: 36px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}