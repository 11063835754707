.div {
  padding-top: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  .label {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
  .icon {
    height: 24px;
    width: 24px;
  }
}
