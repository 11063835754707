.perils {
    overflow-y: auto;
    max-height: calc(100vh - 226px);

    .checkbox {
        display: flex;
        align-items: center;
        padding: 0 10px 0 28px;
        position: relative;
    }

    .checkboxDisabled {
        padding: 0 10px 0 28px;
        color: var(--gray-border)
    }

    .checkbox:hover {
        background-color: #0000000a;
        // cursor: pointer;

        .icons {
            position: absolute;
            right: 0px;
            display: flex;
            justify-content: space-between;
            background: transparent;
            border-radius: 4px;
            // width: 36px;
            height: 36px;
            z-index: 50;
            color: var(--gray-text);
            background: linear-gradient(90deg, rgba(238, 238, 238, 0) 0, #f5f5f5 10px);
        }
    }

    .icons {
        position: absolute;
        right: 0px;
        display: none;
    }

    .premium {
        height: 24px;
        width: 24px;
        margin-left: 10px;
    }
}