.select {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;

	.checkbox {
		display: flex;
		flex-flow: row;
		align-items: center;
	}
}

.activeButton {
	display: flex;
	flex-flow: row;
	align-items: center;
	justify-content: flex-start;
	border: 1px solid rgba(33, 33, 33, 0.12);
	border-radius: 4px;
	// padding: 6px, 38px, 6px 8px;
	padding: 3px;
	background-color: #c1e5ca;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	cursor: pointer;

	.inline {
		display: flex;
		align-items: center;
	}

	.premium {
		height: 24px;
		width: 24px;
		margin-left: auto;
		margin-right: 10px;
	}

	.image {
		height: 36px;
		width: 36px;
		padding: 6px;
		fill: #007934;
	}
}

.disabledButton {
	@extend .activeButton;
	border: 1px solid rgba(0, 0, 0, 0.26);
	background-color: white;
	color: rgba(0, 0, 0, 0.38);

	.inline {
		color: rgba(0, 0, 0, 0.38);
	}

	.image {
		fill: rgba(0, 0, 0, 0.38);
	}
}

.button {
	@extend .activeButton;
	background-color: white;
	color: #000000de;

	.image {
		fill: var(--gray-text);
	}
}

.icon {
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 46px;
	width: 46px;
}

.buttonRestDisabled {
	// background: #e0e0e0;
	color: #999;
}

.buttonRest {
	background: #fff;
	color: #007934;
}

.buttonRest:hover {
	background: #f5faf7;
	color: #007934;
}

.buttonRest:active {
	background: #ddede3;
	color: #007934;
}

.title {
	font-size: 12px;
	color: #999;
}

.closeButton {
	cursor: pointer;
}

.closeButton:hover {
	background: #f5faf7;
}