.icon {
  width: 1.5em;
  height: 1.5em;
  //   opacity: 0.5;
  fill: var(--gray-text);
}

.disabled {
  width: 1.5em;
  height: 1.5em;
  fill: rgba(0, 0, 0, 0.38);
}