.hail {
  width: 100%;
  height: 100%;

  .detail {
    width: 100%;
    margin-bottom: 6px;
    color: #666;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
  
    .key {
      width: 60%;
    }
    
    .value {
      font-weight: 600;
    }
  }
  
}
