.analyse {
  // width: 780px;

  .header {
    display: flex;
    align-items: center;
    height: 58px;
    border-radius: 4px;
    background-color: var(--gray-background);
    border: 1px solid var(--gray-border);
    margin: 10px 0;

    .image {
      height: 40px;
      width: 40px;
      margin: 10px;
      fill: var(--gray-text)
    }
  }
}

.container {
  border-radius: 4px !important;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
  padding: 24px 24px 18px;


  .header {
    font-weight: 600;
    font-size: 16px;
    opacity: 0.79;
    color: #333
  }
}