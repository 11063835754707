.home {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex: 1;
    height: calc(100vh - 56px);
    width: calc(100% - 88px);
}

.arrowLeftIcon {
    position: absolute;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    left: -1px;
    width: 24px;
    height: 48px;
    border-radius: 0 4px 4px 0;
    box-shadow: 0 2px 4px #0003, 0 1px 10px #0000001f, 0 4px 5px #00000024;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        height: 24px;
        width: 24px;
        color: #0009;
        zoom: 1.2;
    }
}

.arrowRightIcon {
    position: absolute;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    width: 24px;
    height: 48px;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 0 2px 4px #0003, 0 1px 10px #0000001f, 0 4px 5px #00000024;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    span {
        height: 24px;
        width: 24px;
        color: #0009;
        zoom: 1.2;
    }
}

.dialogHeader {
    display: flex;
    padding: 24px 40px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    background: var(--premium-gradient-2, linear-gradient(180deg, #fbbb18 0%, #b77718 100%));

    .dialogIconTitle {
        background: var(--premium-gradient-2, linear-gradient(180deg, #fbbb18 0%, #b77718 100%));
        display: flex;
        align-items: center;
        // justify-content: space-between;
        border-radius: 27px;
        font-size: 12px;
        color: #fff;
        padding: 4px 8px;
        gap: 4px;
        width: 110px;
    }

    .dialogTitle {
        color: var(--Surface-Surface-White, var(--Color, #fff));
        font-feature-settings: "clig" off, "liga" off;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .dialogSubtitle {
        color: var(--Surface-Surface-White, var(--Color, #fff));
        font-feature-settings: "clig" off, "liga" off;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: .033px;
    }
}

.dialogContent {
    margin: 34px 0px 0 35px;
    padding-right: 50px;
    overflow: auto;
}

.dialogFoot {
    padding: 16px 0;
    margin-bottom: 0;
    margin-right: 10px;
    min-height: 36px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}